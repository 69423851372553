/* Keyframes for fading in */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
/* Keyframes for fading out */
@keyframes fadeOut {
from { opacity: 1; }
to { opacity: 0; }
}
  
  .loading-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      animation: fadeIn 0.5s ease-out forwards;
  }
  
  .loading-overlay.hide { /* Class to apply when hiding */
      animation: fadeOut 0.5s ease-out forwards;
  }
  
  .loading-content {
      width: 35%;
      padding: 2%;
      background-color: rgba(255, 255, 255, 0.85);
      border-radius: 10px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
      text-align: center;
      color: #333;
  }

  .loading-detail-msg{
    font-size: 85%;
    color: darkgray;
  }
  
  .loading-image {
      width: 100px;
      height: auto;
      margin-bottom: 20px;
      background: none;
  }
  
@media (max-width: 768px) {
    .loading-content {
        width: 80%; 
    }
    .loading-content p{
      font-size: 110%;
    }
    .loading-detail-msg{
        font-size: 92%;
      }

}