/* Base styles for the header */
.header {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: rgb(17,16,16);
    color: white;
    flex-wrap: nowrap;
    overflow: hidden;
}

.header-left img { /* Druckhous logo*/
    height: 20px;
    width: auto;
}

.header-mid {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
}

.header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 120%;
}

.header-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 115px;
    flex-shrink: 0;
}

.menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
}

.pipe {
    color: white;
    font-weight: bold;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 4px;
}

/* Media query for mobile devices */
@media (max-width: 600px) {
    .header {
        justify-content: space-between;
        padding: 5px 10px;
    }

    .header-right {
        font-size: 100%;
        flex-shrink: 0;
    }

    .header-mid {
        margin: 0;
        flex-grow: 2;
        overflow: hidden;
    }

    .menu a {
        font-size: 0.75rem;
    }
}
