.option-menu {
    /* background-color: transparent; */
    border: 1px solid #e9e9e9;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: absolute;
    z-index: 1000; 
    overflow-y: auto;
    background: white;
    margin-top: -14px;
}

.option-menu-item {
padding: 3px;
cursor: pointer;
background-color: #e9e9e9;
margin: 1px auto;
width: 90%;
text-align: center;
}

.option-menu-item:hover {
background-color: #009EE2;
}

@media only screen and (max-width: 768px) {
.option-menu-item:active {
    background-color: #009EE2;
    }
    .option-menu-item:hover {
    background-color: #009EE2;
    }
}



/*
.menu-cell {
  position: absolute;
  z-index: 1000; 
  width: 100%;
  background: white;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ccc;
}
*/
