/* src/components/Footer/Footer.css */
.footer {
    height: 50px;
    display: flex;
    justify-content: center;
    padding: 15px;
    align-items: center;
    text-align: center;
    background-color: rgb(17, 16, 16);
    color: white;
}

.footer p{ /* lines are too close to eachother*/
    line-height: 1.5;  
    margin: 0;
}

.pipe {
    color: white;
    font-weight: bold;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 4px;
}