:root {
  --header-footer-total-height: 170px; 
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: calc(9px + 0.5vw);
  overflow-x: hidden  ;
  overflow-y: auto;
}

body {
  max-width: 80vw;
  margin: 0 auto;
  overflow-x: hidden;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.footer a{
  color: white;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 5px;
  height: calc(100vh - var(--header-footer-total-height));
  overflow: hidden;
}

.left, .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  height: 100%;
  justify-content: center;
}

.left {
  max-width: 50%;
  border: 2px dashed #7e7e7e5e;
  padding: 3px;
  overflow-y: auto; 
  box-sizing: border-box;

}

.left * {
  max-width: 100%;
  height: auto;
  overflow-wrap: break-word;
}

/* .left > :first-child {
  margin-top: 20px;
} */

.right {
  display: flex;
  flex-direction: column;
  min-width: 50%;
  max-width: 100%;
  height: 100%;
  max-height: calc(100vh - 50px - 100px - 20px);
  align-items: center;
  justify-content: center;
  margin: 1px;
  overflow: hidden;
  position: relative;
  flex: 1;
  text-align: center;
}

.right canvas {
  margin: auto;
  padding: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: scale-down;
  max-height: calc(100vh - 50px - 100px - 20px);
  width: 100%;
  max-width: 100%;
}


.pdf-orig-hiddden-size{
  width:100%;
  height: auto;
  display: none;
}

.default-image {
  opacity: 0.9;
  width: 50%;
  margin: 0 auto;
}

.other-image {
  opacity: 1;
  width: 100%;
  margin: 0 auto;
  /* border: 1px solid black; */
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.error{
  color: red;
  font-weight: bold;
  text-align: center;
}

button {
  background-color: #eeeeee; /* Lighter shade of #009EE2 */
  color: #333; /* Dark text color for contrast */
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: calc(9px + 0.5vw);
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  white-space: nowrap; 
}
.navigation button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
}

 /* To make the table cell's content centerable */
.table-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.price-div{
  position: sticky;
  bottom: 0; 
  background-color: gold;
  box-shadow: 0px 2px 5px 8px #d4d4d425;
  z-index: 10;
  width: 60%;
  height: auto;
  overflow-wrap: break-word;
  border:2px solid #ffffff;
  border-radius: 10px;
}

.price-div td{
  padding: 0px;
  line-height: 0px;
  padding-left: 5px;
  padding-right: 5px;
}

.price-div p{
  font-weight: bold;
  font-size: 120%;
  text-overflow: ellipsis;
  color: #009EE2;
  text-shadow: 0 0 3px yellow;
  letter-spacing: 1px;
  text-align: center;
}

/* .price-div td:first-child p {
  text-align: center;  
} */

.switch {
  position: relative;
  display: inline-block;
  height: 21px;
  width: 50px;
  margin-top: 2px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #7c7c7c; /* S/w color */
  transition: .41s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px; 
  width: 16px; 
  left: 2px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #D20173; /* Color color */
  transform: translateX(0px);
}

input:checked + .slider:before {
  transform: translateX(30px);
}

.slider.round {
  border-radius: 4px;
}

.slider.round:before {
  border-radius: 15%;
}

.dragging {
  background-color: #eee;
}

.dragging p {
  color: #aaa;
}

.dragging.left {
  border-color: #aaa;
}
f
.dragging.right {
  border-color: #aaa;
}

div.left-container-informationholder{
  width: 95%;
  justify-content: center;
  align-items: center;
}

table.result-main-table{
  width: 100%;
  box-sizing: border-box;
  table-layout: fixed;
  border: 1px solid #f2f2f2;
  margin-bottom: 5px;
  border-collapse: collapse;
}

.result-to-show-cell {
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
  max-width: 30%;
}

.result-main-table td {
  position: relative;
  padding-left: 2px;
  padding-right: 2px;
}

p.info-of-result-to-show{
  margin-left: 10px;
  text-align: left;
}


p.result-to-show {
  color: #009EE2;
  text-shadow: 0 0 3px yellow;
  font-weight: bold;
  font-size: 120%;
  text-align: right;
  margin-left: 10px auto;
  line-height: 0px;
  margin-right: 10px auto;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

p.result-to-show-3-row{
  color: #009EE2;
  text-shadow: 0 0 3px yellow;
  font-weight: bold;
  font-size: 120%;
  text-align: left;
  margin-left: 0px auto;
  line-height: 0px;
}

span.pipe{
  color: #D20173;
  font-weight: bold;
  margin-left: 12px;
  margin-right: 12px;
}

p.percentage-info-text {
  margin-top: -5px;
  margin-bottom: 15px;
  width: 100%;
  color: #9e9e9e;
  text-align: center;
  white-space: nowrap; 
  font-size: 80%;
}

p.menu a{
  color: white;
  font-weight: bold;
  text-decoration: none;
}

p.menu-link{
  background-color: #333;
  color: #fff;
  border: none;
  padding: 4px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
  font-size: calc(10px + 0.5vw);
  text-align: center;
}

p.menu-link.disabled-menu{
  cursor: not-allowed; 
  color: white;
  background-color: rgb(175, 175, 175);
}

span.tooltip {
  position: absolute;
  visibility: hidden;
  text-align: left;
  width: 90%;
  background-color: #0194D3;
  padding: 3px;
  padding-left: 7px;
  border-radius: 0px 7px 7px 7px; 
  color: white;
  font-size: 85%;
  transform: translateX(-50%);;
  z-index: 1;
}

p.rainbow, p.gray-gradient {
  margin: 0;
  padding: 0; 
  line-height: 1.5;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: bold;
}

p.rainbow {
  background-image: linear-gradient(to right, #D20173, #009EE2, yellow);
}

p.gray-gradient {
  background-image: linear-gradient(to left,#b1b1b1, #303030, #9b9b9b);  
}

p.welcome-text{
  color: #333;
  font-size: 0.9em;
  text-shadow: none;
  margin-bottom: 20px;
  text-align: center;
  padding: 35px;
}

p.drag-here-text{
  font-size: 0.9em;
  color: #555; 
  text-shadow: none;
  text-align: center;
}

.deminsion-table p{
  color: #009EE2;
  text-shadow: 0 0 3px yellow;
  font-weight: bold;
}

.deminsion-table tr{
  line-height: 0px;
}

button:hover, .navigation button.menu-link:hover, p.menu-link:hover {
  background-color: #0194D3; /* Lighter shade of #D20173 */
  color: white;
  text-align: center;
}

p.menu-link.disabled-menu:hover{
  background-color: rgb(175, 175, 175);
  color: white;
}

p.menu-link.disabled-menu:hover span.tooltip{
  visibility: visible;
}

.slider:hover::before, .slider:hover::before{
  border-radius: 20px;
}

@media only screen and (max-width: 768px){
  html, body{
    overflow-y: scroll;
    height: 100%;
    margin: 0%;
    padding: 0%;
    max-width: 100vw;
  }

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    line-height: 0px;
    flex-direction: column;
  }

  .header{
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 5px;
    padding-bottom: 10px;
  }

  .header-left{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header-left img{
    max-height: 30px;
  }

  .header a{
    font-size: 120%;
  }
  
  .header-mid {
    flex: 1;
    text-align: center;
  }
  
  .header-right {
    margin-top: auto;
    text-align: center;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 0;  
    margin: 0%;
    overflow-y: auto;
  }

  .left {
    max-width: 100%;
    width: 99%;
    box-sizing: border-box;
    min-height: 100%;
    overflow: hidden;
  }
  
  .right {
    max-width: 100%;
    min-height: 25vh;
    max-height: 30vh;
    overflow: hidden;
  }
  
  p.percentage-info-text {
    font-size: 1.1vh;
  }

  button {
    font-size: 1.3vh;
  }

  button:hover {
    background-color: #eeeeee; /* Lighter shade of #009EE2 */
    color: #333;
  }

  .navigation button:hover {
    background-color: #333;
  color: #fff;
  }

  .left.valid,
  .left.invalid {
    max-height: 100%;
  }

  .slider.round {
    border-radius: 4px;
  }
  
  .slider.round:before {
    border-radius: 15%;
  }
}